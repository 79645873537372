<template>
  <div>
    <div class='md-layout md-gutter'>
      <div class='md-layout-item md-layout md-gutter'>
        <div class='md-layout-item md-gutter'>
          <stats-card header-color='green'>
            <template slot='header'>
              <div class='card-icon'>
                <md-icon>build</md-icon>
              </div>
              <p class='category'>
                {{ `${$t('statscard.the_total_cost_of_the_plan')}` }}
              </p>
              <h3 class='title'>
                {{ (+sumPlanWorksCost / 1000).toFixed(3) }}
              </h3>
            </template>

            <template slot='footer'></template>
          </stats-card>
        </div>
      </div>
      <div class='md-layout-item md-layout md-gutter'>
        <div class='md-layout-item md-gutter'>
          <stats-card header-color='rose'>
            <template slot='header'>
              <div class='card-icon'>
                <md-icon>check_circle_outline</md-icon>
              </div>
              <p class='category'>
                {{ `${$t('statscard.total_cost_of_selected_works')}` }}
              </p>
              <h3 class='title'>
                {{ (sumSelectedPlanWorksCost / 1000).toFixed(3) }}
              </h3>
            </template>
          </stats-card>
        </div>
      </div>
      <div class='md-layout-item md-layout md-gutter md-alignment-center-right'>
        <template v-if='!isCurrPlanLocked'>
          <template v-if='show_av_works'>
            <md-button
              class='md-success button'
              v-if='isAdmin'
              @click='onSaveSelectedWorks'
            >
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class='md-default button' @click='onCancelSelectedWorks'>
              {{ $t('buttons.cancel') }}
            </md-button>
          </template>
          <template v-else-if='!show_av_works && me.access_level === 2'>
            <md-button
              v-if='me.group_id > 10'
              class='md-primary button'
              @click='onShowAvWorks'
              :disabled='!selected_plan'
            >
              {{ $t('buttons.add') }}
            </md-button>
          </template>
        </template>
      </div>
    </div>
    <CollapseTransition :duration='500'>
      <Avworks v-if='show_av_works' v-model='selected_works'></Avworks>
    </CollapseTransition>
    <div class='md-layout'>
      <div class='md-layout-item'>
        <md-card>
          <md-card-header class='md-card-header-icon md-card-header-green'>
            <div class='card-icon'>
              <md-icon>assignment</md-icon>
            </div>
            <div class='plans-select'>
              <div class='export-icon' @click='onExport("approved")'>
                <md-icon
                  :disabled='isExportGetting'
                  :title="$t('buttons.export_approved_plan_items')"
                  style='font-size: 40px !important;'
                >description

                </md-icon>
              </div>
              <div :class="[selected_plan_works.length !== 0 ? 'export-icon': 'export-icon-disabled']"
                   @click='onExport("selected")' :disabled='true'>
                <md-icon
                  :disabled='selected_plan_works.length === 0'
                  :title="$t('buttons.export_selected_plan_items')"
                  style='font-size: 40px !important;'
                >task
                </md-icon>
              </div>
              <div class='export-icon' @click='onExport'>
                <md-icon :disabled='isExportGetting'
                         :title="$t('buttons.export_full_plan')"
                         style='font-size: 40px !important;'
                >sim_card_download
                </md-icon>
              </div>
              <md-field class='plans-select-dropdown'>
                <label for='plan'>{{ $t('label.plans') }}</label>
                <md-select
                  v-model='selected_plan'
                  name='plan'
                  id='plan'
                  @md-selected='onPlanChange'
                >
                  <md-option
                    v-for='(plan, ind) in planList'
                    :key='ind'
                    :value='plan.id'
                  >
                    {{ plan.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </md-card-header>
          <md-card-content>
            <md-table
              :value='queriedData'
              @md-selected='onSelect'
              :md-sort.sync='currentSort'
              :md-sort-order.sync='currentSortOrder'
              :md-sort-fn='customSort'
              class='paginated-table table-striped table-hover'
            >
              <md-table-toolbar>
                <md-field>
                  <label for='pages'>{{ $t('label.per_page') }}</label>
                  <md-select v-model='pagination.perPage' name='pages'>
                    <md-option
                      v-for='item in pagination.perPageOptions'
                      :key='item'
                      :label='item'
                      :value='item'
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field>
                  <md-input
                    type='search'
                    class='mb-3'
                    clearable
                    style='width: 200px'
                    :placeholder="`${$t('label.search_records')}`"
                    v-model='searchQuery'
                  ></md-input>
                </md-field>
              </md-table-toolbar>

              <md-table-row
                slot='md-table-row'
                slot-scope='{item}'
                md-selectable='multiple'
                md-auto-select
              >
                <md-table-cell
                  v-for='title in planWorksTitles'
                  :key='title'
                  :md-label="$t(`tables.${title==='cost'? 'cost_thousands': title}`)"
                  :md-sort-by='title'
                >
                  <template v-if="title === 'cost'">
                    <div class='text-allign-right'>
                      {{ ((item.cost / 1000).toFixed(2) * 100) / 100 }}
                    </div>
                  </template>
                  <template v-else-if="title === 'road_key'">
                    <a href='#' @click.stop.prevent='onWorkDetails(item.work_id)'>
                      {{ item[title] }}
                    </a>
                  </template>
                  <template v-else>{{ item[title] }}</template>
                </md-table-cell>

                <md-table-cell
                  :md-label="$t('tables.plan_item_status')"
                  style='text-align: left; font-weight: 500; '
                >
                  <template v-if='getStatus(item)=== "approved"'>
                    <span style='color:green; padding: 0 2px;'>Одобренно</span>
                  </template>
                  <template v-else-if='getStatus(item)=== "indeterminate"'>
                    <span style='color:#848788; padding: 0 2px;'>На рассмотрении в ДДХ</span>
                  </template>
                  <template v-else>
                    <span style='color:#848788; padding: 0 2px;'>На рассмотрении в РО/УАД</span>
                  </template>
                </md-table-cell>
                <md-table-cell
                  v-if='isAdmin && !isCurrPlanLocked'
                  :md-label="$t('tables.actions')"
                >
                  <div class='cell-actions'>
                    <md-button
                      v-if='isActionEnable(item)'
                      class='md-raised md-sm'
                      :class="[
                        isActionEnable(item) === 'add'
                          ? 'md-success'
                          : 'md-danger'
                      ]"
                      @click.stop.prevent='onApproval([item])'
                    >
                      {{
                        isActionEnable(item) === 'add'
                          ? isDeuUser
                            ? $t('buttons.submit')
                            : $t('buttons.approve')
                          : $t('buttons.revoke')
                      }}
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class='footer-table md-table'>
              <table>
                <tfoot>
                <tr>
                  <th
                    v-for='item in footerTable'
                    :key='item'
                    class='md-table-head'
                  >
                    <div
                      class='md-table-head-container md-ripple md-disabled'
                    >
                      <div class='md-table-head-label'>{{ item }}</div>
                    </div>
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <div
            class='md-layout-item md-layout md-gutter md-alignment-center-right'
          >
            <md-button
              class='md-success button'
              :disabled='selected_plan_works.length === 0'
              @click='onApprovalGroup'
            >
              {{ $t('buttons.change_group') }}
            </md-button>
          </div>
          <md-card-actions md-alignment='space-between'>
            <div class>
              <p class='card-category'>
                {{
                  $t('label.showing_from_to_of_entries', {
                    from: to > 0 ? from + 1 : 0,
                    to: to,
                    total
                  })
                }}
              </p>
            </div>
            <pagination
              class='pagination-no-border pagination-success'
              v-model='pagination.currentPage'
              :per-page='pagination.perPage'
              :total='total'
            ></pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <modal v-if='showWorkInfo'>
      <template slot='header'>
        <h4 class='modal-title'>{{ $t('label.detail_description') }}</h4>
        <md-button
          class='md-simple md-just-icon md-round modal-default-button'
          @click='showWorkInfo = false'
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot='body'>
        <div class='modal-scroll-content'>
          <div
            class='modal-content-row'
            v-for='(item, index) in work_vars'
            :key='item'
            :class="{'gray-row': index % 2 === 0}"
          >
            <div class='modal-content-title'>
              <span>{{ $t(`roads_details.${item}`) }}:</span>
            </div>
            <div class='modal-content-text'>
              <span>{{ work_info[item] }}</span>
            </div>
          </div>
        </div>
      </template>

      <template slot='footer'>
        <md-button class='md-danger md-simple' @click='showWorkInfo = false'>
          {{ $t('buttons.close') }}
        </md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal, Pagination, StatsCard} from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import {CollapseTransition} from 'vue2-transitions'
import Avworks from '@/pages/Dashboard/av_works'
import {mapState} from 'vuex'

export default {
  components: {
    Pagination,
    CollapseTransition,
    Avworks,
    StatsCard,
    Modal
  },
  computed: {
    ...mapState({
      avWorksTitles: (state) => state.Planning.av_works_list.vars,
      planWorksTitles: (state) => state.Planning.plan_works_list.vars,
      planWorksData: (state) => state.Planning.plan_works_list.data,
      me: (state) => state.Login.me,
      currentPlan: (state) => state.Plans.currentPlan,
      planList: (state) => state.Plans.list
    }),
    isCurrPlanLocked() {
      const currPlan = this.planList.find(
        (item) => item.id === this.selected_plan
      )
      return currPlan ? Boolean(currPlan.locked_at) : false
    },
    isDeuUser() {
      return this.me.access_level === 2
    },
    sumPlanWorksCost() {
      return this.planWorksData.length > 0
        ? this.planWorksData.reduce(function(accumulator, currentValue) {
          return accumulator + +currentValue.cost
        }, 0)
        : 0
    },
    sumSelectedPlanWorksCost() {
      return this.selected_plan_works.length > 0
        ? this.selected_plan_works.reduce(function(accumulator, currentValue) {
          return accumulator + +currentValue.cost
        }, 0)
        : 0
    },
    isAdmin() {
      return this.me.role === 'admin'
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      const result = !this.searchQuery
        ? this.planWorksData
        : this.searchedData
          ? this.searchedData
          : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery
        ? this.searchedData.length
        : this.planWorksData.length
    }
  },
  data() {
    return {
      currentSort: 'cost',
      currentSortOrder: 'desc',
      isExportGetting: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      show_av_works: false,
      footerTable: this.works_list_titles,
      searchQuery: '',
      propsToSearch: [
        'road_key',
        'section',
        'length',
        'condition_index',
        'functional_index',
        'priority_index',
        'treatment_description',
        'treatment_description_en',
        'cost',
        'dep',
        'region_key'
      ],
      tableData: [],
      tableHeaders: [],
      selected_works: [],
      selected_plan: null,
      selected_plan_works: [],
      searchedData: [],
      fuseSearch: null,
      //plans: [],
      work_vars: [],
      work_info: {},
      showWorkInfo: false
    }
  },
  methods: {
    getStatus(item) {
      if (item.approved_by > 0 && item.approved_ddh_by > 0) {
        return 'approved'
      } else if (item.approved_by === 0 && item.approved_ddh_by === 0) {
        return 'check_box_outline_blank'
      } else {
        return 'indeterminate'
      }
    },
    async onExport(type = 'all') {
      this.isExportGetting = true
      let work_ids = []
      const plan_id = this.selected_plan
      const plan = this.planList.find((item) => {
        return item.id === plan_id
      })
      const plan_name = plan['name']
      if (type === 'selected') {
        work_ids = this.selected_plan_works.map(work => work.work_id)
      }
      try {
        this.$store.dispatch('PLAN_EXPORT', {plan_id, plan_name, type, work_ids})
      } finally {
        this.isExportGetting = false
      }
    },
    isActionEnable(item) {
      const accessLevel = this.me.access_level
      const {submitted_by, approved_by, approved_ddh_by} = item
      let res = undefined
      switch (accessLevel) {
        case 0: // DDH level
          res =
            submitted_by && approved_by && !approved_ddh_by
              ? 'add'
              : submitted_by && approved_by && approved_ddh_by
                ? 'del'
                : undefined
          break
        case 1: // RO|UAD level
          res =
            submitted_by && !approved_by
              ? 'add'
              : submitted_by && approved_by && !approved_ddh_by
                ? 'del'
                : undefined
          break
        case 2: // DEU level
          res = !submitted_by ? 'add' : !approved_by ? 'del' : undefined
          break
      }
      return res
    },
    onApprovalGroup() {
      const selected = this.selected_plan_works
      if (selected.length === 0) {
        return
      }
      this.onApproval(selected)
    },
    onApproval(items) {
      const id = this.selected_plan
      const works = items.map((item) => item.work_id)
      this.$store.dispatch('PLAN_WORKS_APPROVAL', {id, works}).then(() => {
      })
    },
    onWorkDetails(work_id) {
      this.work_info = ''

      this.$store
        .dispatch('LOAD_PLAN_WORK_INFO', {plan_id: this.selected_plan, work_id})
        .then((res) => {
          this.work_info = res.data[0]
          this.work_vars = [...res.vars]
        })
      this.showWorkInfo = true
    },
    workInfoHide() {
      this.showWorkInfo = false
    },
    onUpdateList(id) {
      this.$store.dispatch('LOAD_PLAN_WORKS', id).then(() => {
        this.fuseSearch = new Fuse(this.planWorksData, {
          keys: this.propsToSearch,
          threshold: 0.3
        })
      })
    },
    onPlanChange() {
      const id = this.selected_plan
      this.onUpdateList(id)
    },
    onSelect(items) {
      this.selected_plan_works = items
    },
    onShowAvWorks() {
      this.$store
        .dispatch('LOAD_AV_WORKS_LIST', this.selected_plan)
        .then(() => {
          this.show_av_works = true
        })
    },
    onSaveSelectedWorks() {
      const id = this.selected_plan
      const works = this.selected_works.map((work) => {
        return work.work_id
      })

      this.$store.dispatch('ADD_PLAN_WORKS', {id, works}).then(
        () => {
          this.show_av_works = true
          this.onPlanChange(this.selected_plan)
          this.onCancelSelectedWorks()
        },
        () => {
          this.onCancelSelectedWorks()
        }
      )
    },
    onCancelSelectedWorks() {
      this.show_av_works = false
    },
    customSort(value) {
      console.log({value})
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy]
            .toString()
            .localeCompare(b[sortBy].toString(), {}, {numeric: true})
        }
        return b[sortBy]
          .toString()
          .localeCompare(a[sortBy].toString(), {}, {numeric: true})
      })
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success'
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info'
      })
    },
    handleDelete(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        cancelButtonClass: 'md-button md-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item)
          Swal.fire({
            title: 'Deleted!',
            text: `You deleted ${item.name}`,
            type: 'success',
            confirmButtonClass: 'md-button md-success btn-fill',
            buttonsStyling: false
          })
        }
      })
    },
    deleteRow(item) {
      let indexToDelete = this.planWorksData.findIndex(
        (tableRow) => tableRow.id === item.id
      )
      if (indexToDelete >= 0) {
        //this.tableData.splice(indexToDelete, 1)
      }
    }
  },
  created() {
    this.$store.dispatch('LOAD_PLAN_LIST').then(() => {
      const defPlan = this.currentPlan ? this.currentPlan : this.planList[0].id
      this.selected_plan = defPlan
      this.onUpdateList(defPlan)
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  }
}
</script>

<style lang='scss' scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.modal-scroll-content {
  display: flex;
  overflow-y: auto;
  //z-index: 9999;
  flex-direction: column;
  max-height: 30rem;

  .modal-content-row {
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-content-title {
      font-weight: 600;
    }

    .modal-content-text {
      min-width: 100px;
      padding-left: 15px;
    }
  }
}

.plans-select {
  display: flex;

  position: relative;
  top: 20px;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;

  icon {
    margin-right: 10px;
  }

  .plans-select-dropdown {
    width: 300px;
    margin-left: 10px;
  }
}

.text-allign-right {
  text-align: right;
}

.button {
  margin-left: 10px;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.gray-row {
  background: #ebe9e9;
}

.export-icon {
  opacity: 0.5;
  cursor: pointer;
}

.export-icon-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.export-icon:hover {
  opacity: 1;

  icon {
    color: red !important;
  }
}
</style>
