<template>
  <div class='md-layout md-alignment-center-right'>
    <div class='md-layout-item'>
      <md-card>
        <md-card-header class='md-card-header-icon md-card-header-green'>
          <div class='card-icon'>
            <md-icon>assignment</md-icon>
          </div>
          <h4 class='title'>{{ $t('planning.available_works_table') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            @md-selected='onSelect'
            :value='queriedData'
            :md-sort.sync='currentSort'
            :md-sort-order.sync='currentSortOrder'
            :md-sort-fn='customSort'
            class='paginated-table table-striped table-hover'
          >
            <md-table-toolbar>
              <md-field>
                <label for='pages'>{{ $t('label.per_page') }}</label>
                <md-select v-model='pagination.perPage' name='pages'>
                  <md-option
                    v-for='item in pagination.perPageOptions'
                    :key='item'
                    :label='item'
                    :value='item'
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type='search'
                  class='mb-3'
                  clearable
                  style='width: 200px'
                  :placeholder="`${$t('label.search_records')}`"
                  v-model='searchQuery'
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row
              slot='md-table-row'
              slot-scope='{item}'
              md-selectable='multiple'
              md-auto-select
            >
              <md-table-cell
                v-for="title in worksTitles.filter(
                  (item) => item !== 'work_id'
                )"
                :key='`${item}${title}`'
                :md-label="$t(`tables.${title==='cost'? 'cost_thousands': title}`)"
                :md-sort-by='title'
              >
                <template v-if="title == 'cost'">
                  {{ item[title] / 1000 }}
                </template>
                <template v-else-if="title !== 'road_key'">
                  <a href='#' @click.prevent.stop='onWorkDetails(item.work_id)'>
                    {{ item[title] }}
                  </a>
                </template>
                <template v-else>
                  {{ item[title] }}
                </template>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment='space-between'>
          <div class>
            <p class='card-category'>
              {{
                $t('label.showing_from_to_of_entries', {
                  from: to > 0 ? from + 1 : 0,
                  to: to,
                  total
                })
              }}
            </p>
          </div>
          <pagination
            class='pagination-no-border pagination-success'
            v-model='pagination.currentPage'
            :per-page='pagination.perPage'
            :total='total'
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    <modal v-if='showWorkInfo'>
      <template slot='header'>
        <h4 class='modal-title'>{{ $t('label.detail_description') }}</h4>
        <md-button
          class='md-simple md-just-icon md-round modal-default-button'
          @click='showWorkInfo = false'
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot='body'>
        <div class='modal-scroll-content'>
          <div
            class='modal-content-row'
            v-for='(item, index) in work_vars'
            :key='item'
            :class="{'gray-row': index % 2 === 0}"
          >
            <div class='modal-content-title'>
              <span>{{ $t(`roads_details.${item}`) }}:</span>
            </div>
            <div class='modal-content-text'>
              <span>{{ work_info[item] }}</span>
            </div>
          </div>
        </div>
      </template>

      <template slot='footer'>
        <md-button class='md-danger md-simple' @click='showWorkInfo = false'>
          {{ $t('buttons.close') }}
        </md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal, Pagination} from '@/components'
import Fuse from 'fuse.js'
import {mapState} from 'vuex'

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    ...mapState({
      worksTitles: (state) => state.Planning.av_works_list.vars,
      worksData: (state) => state.Planning.av_works_list.data
    }),
    queriedData() {
      const result = !this.searchQuery
        ? this.worksData
        : this.searchedData
          ? this.searchedData
          : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.worksData.length
    }
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Array
  },

  data() {
    return {
      currentSort: '',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      show_av_works: false,
      footerTable: [],
      searchQuery: '',
      selectedList: this.selected,
      searchedData: [],
      fuseSearch: null,
      showWorkInfo: false,
      work_info: '',
      work_vars: []
    }
  },
  methods: {
    onSelect(items) {
      this.selectedList = items
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    onWorkDetails(id) {
      this.work_info = ''
      this.$store.dispatch('LOAD_AV_WORK_INFO', id).then((res) => {
        this.work_info = res.data[0]
        this.work_vars = [...res.vars]
      })
      this.showWorkInfo = true
    },
    workInfoHide() {
      this.showWorkInfo = false
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.worksData, {
      keys: [
        'road_class',
        'road_key',
        'dep',
        'region_key',
        'treatment_description',
        'treatment_description_en',
        'length'
      ],
      threshold: 0.3
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.worksData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    },
    selectedList(newVal) {
      this.$emit('change', newVal)
    }
  }
}
</script>

<style lang='scss' scoped>
/* .av-works {
  -webkit-transition: top 1s ease-out 0.5s;
  -moz-transition: top 1s ease-out 0.5s;
  -o-transition: top 1s ease-out 0.5s;
  transition: top 1s ease-out 0.5s;
} */

.modal-scroll-content {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  max-height: 30rem;

  .modal-content-row {
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-content-title {
      font-weight: 600;
    }

    .modal-content-text {
      min-width: 100px;
      padding-left: 15px;
    }
  }
}

.button {
  margin-left: 10px;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.gray-row {
  background: #ebe9e9;
}
</style>
